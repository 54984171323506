import { createSlice } from "@reduxjs/toolkit";

export const  catalogoSlice = createSlice({
    name:'catalogo',
    initialState:{
        listaProcesos: [],
        listaEstados: [],
        isLoadingProcesos: true,
        fechasProceso:[],
    },

    reducers:{
        onLoadingProcesos: (state, {payload = []}) => {
            state.isLoadingProcesos = false;
            state.listaProcesos = payload;
        },
        onFechasProcesos: (state, {payload = []}) => {
            state.fechasProceso = payload;
        },
        onLoadingEstados:(state, { payload  = []}) => {
            state.isLoadingProcesos = false
            state.listaEstados = payload;
        }
    }
});


export const {
    onLoadingProcesos,
    onLoadingEstados,
    onFechasProcesos
} = catalogoSlice.actions;
