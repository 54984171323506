import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { onLoadingEstados, onLoadingProcesos,onFechasProcesos } from "../store/slices/catalogoSlice";

export const useCatalogoStore = () => {

    const dispatch = useDispatch();
    const { listaProcesos, listaEstados,fechasProceso } = useSelector(state => state.catalogo);

    const startLoadingProcesos = async (isVerified) => {
        console.log('startLoadingProcesos',isVerified);
        if (isVerified) {
            let url = process.env.REACT_APP_API_URL + '/consultaCatalogos';
            try {
                const data = await axios.post(url, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
                const { listaProcesos } = data.data;
                const { fechas } = data.data;
                dispatch(onLoadingProcesos(listaProcesos))
                dispatch(onFechasProcesos(fechas))
            } catch (error) {
                console.log({ error });
            }
        }
    }

    const startLoadingEstadosByProceso = (idDetalleProceso = 0) => {
      console.info("loadingEstados",idDetalleProceso,listaProcesos);
        const proceso = listaProcesos.filter(proceso => proceso.idDetalleProceso === idDetalleProceso);
        if (proceso.length > 0) {
          dispatch(onLoadingEstados(proceso[0].listaEstados));
        }
    }


    return {
        //Metodos
        startLoadingProcesos,
        startLoadingEstadosByProceso,
        // Propiedades
        listaProcesos,
        fechasProceso,
        listaEstados
    }

}
