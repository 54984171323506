/*---------------------------------------------------------------------------------
                                ETIQUETAS Y MENSAJES
 Contiene las etiquetas y mensajes generales del sistema y de cada módulo
---------------------------------------------------------------------------------*/
export const keyIdProceso = "idProceso";
export const keyIdDetalle = "idDetalle";
export const keyIdParticipacion = "idParticipacion";
export const keydAspirante = "idAspirante";
export const keyidAreaResponsabilidad = "idAreaResponsabilidad";

export const mensajeNoEncontroSEyCAE = "No se encontraron datos de SE y CAE para esta sección.";
export const mensajeSEyCAENoLaboraINE = "El SE o CAE dejo de laborar en el instituto.";
export const mensajeAviso = "La información puede cambiar debido a promoción de personal, baja del puesto o causas de enfermedad. Consulta periódicamente para contar con la información más actual.";

export const rutaFotos = "/Glusterfs";
export const idSeguimiento = "G-T87TQ5GHWK";
export const idMedición = "G-HPNBSL1ZZM";
