import axios from "axios";
import React, { useEffect, useState } from "react";
import Template from './Template';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { useParams } from "react-router-dom";
import { useDataSelectStore } from '../hooks';
import { useCatalogoStore} from "../hooks";
//lleva el correo de sed
const CAP_KEY = "6Ld0dTMaAAAAAPrfn9VJZmGjrxcxngTYr1ZawdpT"

const Container = ({ cargando }) => {
    const { proceso, detalle, participacionUrl, aspiranteUrl } = useParams();
    const { setDataAspirante } = useDataSelectStore();
const {fechasProceso } = useCatalogoStore();
    const isVerified = true;

    useEffect(() => {
        if (proceso > 0) {
            setDataAspirante({
                idProceso: Number(proceso),
                idDetalleProceso: Number(detalle),
                idParticipacion: Number(participacionUrl),
                idAspirante: Number(aspiranteUrl),
                seccion: Number(participacionUrl),
                idEstado: 1
            })
        }


    }, [])


    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={CAP_KEY}
            language="es-419" >
            <div>
              <Template
                  cargando={cargando}
                  isVerified={isVerified}
              />
            </div>
        </GoogleReCaptchaProvider>


    );
}
export default Container;
