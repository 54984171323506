import { Button, Col, Modal, Row, Image } from "antd"

import "../assets/css/finSeyCae.css";


import hombre from '../assets/img/iconhombre.svg';
import mujer from '../assets/img/iconmujer.svg';
import logo from '../assets/img/ine_logo_fin.svg';

export const FinSeyCae = ({ }) => {


  return (
    <div id="fin-secae">
      <Row>
        <Col xs={24} sm={24} md={7} lg={7}>
          <img id="hombre" src={hombre}/>
        </Col>
        <Col xs={24} sm={24} md={10} lg={10} id="texto-finsecae">
          <img src={logo} id="logo-ine-fin"/>
          <p>GRACIAS Por ser parte de nuestros</p>
          <h1>SEyCAE</h1>
          <p>La participación la hemos hecho todas y todos. Gracias.</p>
        </Col>
        <Col xs={24} sm={24} md={7} lg={7}>
          <img id="mujer" src={mujer}/>
        </Col>
      </Row>
    </div>
  )
}
export default FinSeyCae;
