import axios from "axios";
import { useDataSelectStore } from "./useDataSelectStore";
import globalVars from "../utils/globalVars";
import { useDispatch, useSelector } from "react-redux";
import { onCleanAspirantes, onLoadingAspirante, onLoadingAspiranteBySeccion } from "../store/slices/aspiranteSlice";
import { onNotViewLoadingScreen, onOpenModal, onViewLoadingScreen } from "../store/slices/uiSlice";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const useAspiranteStore = () => {
    const dispatch = useDispatch();
    const { listaAspirantes, status, code, message } = useSelector(state => state.aspirante);
    const { datosProcesos } = useDataSelectStore();

    const startLoadingAspirantebySeccion = async () => {

        let url = process.env.REACT_APP_API_URL + '/consultaSeCaePorSeccion';
        const token = await window.grecaptcha.execute(globalVars.captchaSecretKey,
          { action: 'submit' });
      console.log("datosProceso",datosProcesos);
        const request = {
            "idProcesoElectoral": datosProcesos.idProceso,
            "idDetalleProceso": datosProcesos.idDetalleProceso,
            "idEstado": datosProcesos.idEstado,
            "seccion": datosProcesos.seccion,
            "idAspirante": datosProcesos.idAspirante,
            "idParticipacion": datosProcesos.idParticipacion,
            "recaptchaResponse" : token,
            "sinReCap" : false

        }

        try {

            const resp = await axios.post(url, request, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            console.log({ resp })

            globalVars.uiText.idProceso = 25;
            globalVars.uiText.idDetalle = datosProcesos.idDetalleProceso;
            globalVars.uiText.idParticipacion = 0;

            const { listaAspirantes, message, status } = resp.data
            console.log({ resp: resp.data })
            const { status: code } = resp
            const listAsp = listaAspirantes !== null ? listaAspirantes : [];
            dispatch(onViewLoadingScreen())
            setTimeout(() => {
                if (listAsp.length === 0) {
                    dispatch(onOpenModal());
                }
                dispatch(onLoadingAspiranteBySeccion({ listAsp, message, status, code }));
                dispatch(onNotViewLoadingScreen())
            }, 1000);

        } catch (error) {
            console.error('mssg:Error consuming:', error);
            dispatch(onOpenModal())
            dispatch(onCleanAspirantes());
        }
    }

    const startLoadingAspirantes = async (datosProcesos) => {
        const { idProceso,
            idDetalleProceso,
            idParticipacion,
            idAspirante,
            idAreaResponsabilidad, } = datosProcesos

        let url = process.env.REACT_APP_API_URL + '/consultaSeCae';
        let jSonAspirante = {
            "idProcesoElectoral": idProceso,
            "idDetalleProceso": idDetalleProceso,
            "idParticipacion": idParticipacion,
            "idAreaResponsabilidad": idAreaResponsabilidad,
            "idAspirante": idAspirante
        }
        try {
            dispatch(onViewLoadingScreen())
            const resp = await axios.post(url, jSonAspirante, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            const { listaAspirantes } = resp.data;
            dispatch(onLoadingAspirante(listaAspirantes));
            dispatch(onNotViewLoadingScreen());

        } catch (error) {
            console.error('mssg:Error consuming:', error);
            dispatch(onCleanAspirantes());
        }

    }

    const startCleanAspirantes = () => {
        dispatch(onCleanAspirantes());
    }


    return {
        //* Propiedades
        listaAspirantes,
        message,
        status,
        code,
        //* Metodos
        startLoadingAspirantebySeccion,
        startLoadingAspirantes,
        startCleanAspirantes
    }
}
