import { Image, Modal, Row, Col } from "antd"
import cinta from '../assets/img/cinta.png'

export const ModalInfo = ({ funcionesAspirante, tipoAspirante = "SE", isOpenModal, openModal }) => {
    const handleCancel = () => {
        openModal(false);
    }


    return (
        <>
            <Modal
                open={isOpenModal}
                onCancel={handleCancel}
                width={500}
                centered
                footer
                className="modal-info"
            >
            <Row >
              <Col xs={24} sm={24} md={24} lg={24} >
                {
                  tipoAspirante === "SE" || tipoAspirante === "CAE"?
                    <Image src={cinta} preview={false} />
                  :""
                }
                {
                  tipoAspirante === "SE" || tipoAspirante === "CAE"?
                  <h1 style={{ margin: 0 }}>{`¿Cuál es la función de un ${tipoAspirante}?`}</h1>
                  :<h2 style={{ margin: 0 }}>Aquí encuentras tu sección electoral</h2>                }
                <Image src={funcionesAspirante} preview={false} />
              </Col>
            </Row>
          </Modal>
        </>
    )
}
