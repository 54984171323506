import { Route, Routes } from "react-router-dom"
import LoadingScreen from 'react-loading-screen';
import { LoginApp, LoginLayaout } from "../componentes/LayoutApp"
import { Aspirantes } from "../pages"
import Layout, { Content,} from "antd/es/layout/layout"
import { Col, Row } from "antd"

import loadingImage from '../assets/img/loader.gif';
import { useUIStore } from "../hooks";
import { useEffect } from "react";

export const Navigate = () => {
    const { isLoading, notViewLoadingScreen } = useUIStore()
    useEffect(() => {
        setTimeout(() => {
            notViewLoadingScreen();
        }, 1000);

    }, []);

    return (
        <Layout>
            <LoadingScreen
                loading={isLoading}
                bgColor='#f1f1f1'
                spinnerColor='#9ee5f8'
                textColor='#676767' logoSrc={loadingImage}>
                <Content >

                  <Routes>
                      <Route path="/:access" element={<LoginApp />} />
                      <Route path="/:proceso/:detalle/:participacionUrl/:aspiranteUrl" element={<LoginLayaout />} />
                      <Route path="/informacionAspirante" element={<Aspirantes />} />
                      <Route path="/" element={<LoginLayaout />} />
                  </Routes>

                </Content>
            </LoadingScreen>
        </Layout >
    )
}
