
import { useRef } from "react"
import MostrarseyCae from '../componentes/SEyCAE'
import { useAspiranteStore, useDataSelectStore } from "../hooks";
import { Button, Col, Row } from "antd";
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import CarruselSECAE from "../componentes/CarruselSECAE";
import { ModalMessage } from "../componentes/ModalMessage";
import * as etiquetas from '../componentes/ApplicationResources';

export const Aspirantes = (props) => {
    const { listaAspirantes, startCleanAspirantes } = useAspiranteStore();
    const { datosProcesos, startCleanDataSelect } = useDataSelectStore();
    const resultsDiv = useRef()
    const navigate = useNavigate();

    const handleReturn = () => {
        console.log('click')
        startCleanAspirantes();
        startCleanDataSelect()
        navigate('/', { replace: true });
    }

    return (
        <div>

            {
                listaAspirantes !== undefined && listaAspirantes.length > 0
                    ?

                    <div>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} >
                            <h1>Lista de Supervisor Electoral y Capacitador(es) – Asistente(s) Electoral(es)</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12} >
                            <h1 className="container-title">Sección: {listaAspirantes !== undefined && listaAspirantes.length > 0 ? listaAspirantes[0].seccionCae : ""}</h1>
                            <p className="resultados-title">Resultados encontrados: {listaAspirantes.length}</p>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} id="btn-nuevaBusqueda" >
                        <Row>
                            <Col xs={16} sm={16} md={16} lg={16} >
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4} >
                              <LeftOutlined id="icono-nuevabusqueda"/>
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4}id="nueva-busqueda" >
                              <a href="#" onClick={handleReturn}>
                                  Nueva <br/>Búsqueda
                              </a>
                            </Col>
                        </Row>
                        </Col>
                    </Row>
                    {
                        listaAspirantes.length !== 0 && listaAspirantes.length >= 3
                            ?
                            <CarruselSECAE />
                            :
                            <Row ref={resultsDiv}>
                                {
                                    listaAspirantes && listaAspirantes.map(aspirante => (
                                        <Col className="gutter-row" >
                                            <MostrarseyCae
                                                key={aspirante.idAspirante}
                                                aspirante={aspirante}
                                                idAspirante='unico'
                                            />
                                        </Col>

                                    ))
                                }
                            </Row>
                    }
                    </div>
                    :
                    <>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} id="div-nohayREsultados">
                        <p>No hay Resultados</p>
                      </Col>
                    </Row>
                    <ModalMessage
                        mensaje={etiquetas
                            .mensajeNoEncontroSEyCAE}
                        tipoMensaje={1}
                    /></>
            }

        </div>
    )
}
