/*React */
import React, { useEffect, useState } from "react";
import MostrarseyCae from '../componentes/SEyCAE';
import CarruselSECAE from "../componentes/CarruselSECAE";
import { Row, Col, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

/*Componentes */

import importantIcon from '../assets/img/importante_icon.svg';
import * as etiquetas from '../componentes/ApplicationResources';
import globalVars from '../utils/globalVars';
import { useAspiranteStore, useDataSelectStore } from "../hooks";
import { ModalMessage } from "../componentes/ModalMessage";
import { useNavigate } from "react-router-dom";

export const InformacionAspirante = ({cargando,isVerified }) => {
    const { datosProcesos, startCleanDataSelect } = useDataSelectStore();
    const { startLoadingAspirantebySeccion, startCleanAspirantes, listaAspirantes } = useAspiranteStore();
    const navigate = useNavigate();

    const { idProceso,
        idDetalleProceso,
        idParticipacion,
        idAspirante,
        idAreaResponsabilidad } = datosProcesos

    const [loading, setLoading] = useState(cargando);
    const [seccionesSe, setSeccionesSe] = useState();
    const [labora, setLabora] = useState(false);
    const [seccionInfoAspirante, setSeccionInfoAspirante] = useState(0);

    const handleReturn = () => {
        startCleanAspirantes();
        startCleanDataSelect();
        navigate('/', { replace: true });
    }

    const actualizarSeccion = (seccionAspirante) =>{
        console.info("info Aspirante " + isVerified);
      setSeccionInfoAspirante(seccionAspirante)
    }

    useEffect(() => {
        obtenerInformacionAspirante();
    }, [])

    useEffect(() => {
        if (listaAspirantes.length > 0) {
            validarAspiranteLabora();
            var secciones = listaAspirantes[0].seccionSe.split(',');
            var result1 = secciones.filter((item,index)=>{
              return secciones.indexOf(item) === index;
            })
            setSeccionesSe(result1.toString())
            console.info("aspirante secciones",secciones,result1.toString());
        }

    }, [listaAspirantes])

    function reemplazarDuplicados(value, index, self) {
      return (self.indexOf(value) === index)?value:'';
    }

    const onChangeLoading = (value) => {
        onChangeLoading([
            cargando
        ]);
    }

    const obtenerInformacionAspirante = () => {
        if (isVerified) {
            actualizarVariables();
            startLoadingAspirantebySeccion()
        }
    }

    const actualizarVariables = () => {
        globalVars.uiText.idProceso = idProceso;
        globalVars.uiText.idDetalle = idDetalleProceso;
        globalVars.uiText.idParticipacion = idParticipacion;
    }

    const validarAspiranteLabora = () => {
        let labora = true;
        if (idAspirante !== undefined && idAspirante !== '0') {
            let aspirante = listaAspirantes[0];
            console.info("aspirante " + aspirante.estatus)
            if (aspirante.estatus !== null && aspirante.estatus !== 'A' &&
                                                    aspirante.estatus !== 'a') {
                setLabora(false);
            }
            setLabora(true)
        }
        return labora;
    }

    return (
        <div className="busqueda">
          <div>
                {
                    listaAspirantes !== undefined && listaAspirantes.length > 0
                        ?

                        labora
                            ?
                            <div className="contenido">
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} >
                                    <h1>Lista de Supervisor Electoral y Capacitador(es) – Asistente(s) Electoral(es)</h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={12} lg={12} >
                                    <h1 className="container-title">{seccionInfoAspirante !=="" ? "Secciones : "+seccionesSe
                                     : "Sección :"+datosProcesos.seccion}</h1>
                                    <p className="resultados-title">Resultados encontrados: {listaAspirantes.length}</p>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} id="btn-nuevaBusqueda" >
                                <Row>
                                    <Col xs={16} sm={16} md={16} lg={16} >
                                    </Col>
                                    <Col xs={4} sm={4} md={4} lg={4} >
                                      <LeftOutlined id="icono-nuevabusqueda"/>
                                    </Col>
                                    <Col xs={4} sm={4} md={4} lg={4}id="nueva-busqueda" >
                                      <a href="#" onClick={handleReturn}>
                                          Nueva <br/>Búsqueda
                                      </a>
                                    </Col>
                                </Row>
                                </Col>
                            </Row>
                                {

                                    listaAspirantes !== undefined && listaAspirantes.length > 1
                                        ?
                                        <CarruselSECAE
                                            onChangeLoading={onChangeLoading}
                                            jsonAspirante={listaAspirantes}
                                        />
                                        :
                                        // mostrar si jsonAspirante.length es menor a 2
                                        <Row className="rowContentSEyCAE" justify="center">
                                            <Col key={"infoAspirante" + listaAspirantes[0].idAspirante}>
                                                <MostrarseyCae
                                                    onChangeLoading={onChangeLoading}
                                                    cargando={loading}
                                                    key={"infoAspirante" + listaAspirantes[0].idAspirante}
                                                    aspirante={listaAspirantes[0]}
                                                    idAspirante={idAspirante}
                                                    idAreaResponsabilidad={idAreaResponsabilidad}
                                                    actualizarSeccion={actualizarSeccion}
                                                />
                                            </Col>
                                        </Row>
                                }

                                <Row className="buscaSEyCAEAviso">
                                  <Col xs={24} sm={24} md={24} lg={24}>
                                    <p id="texto-alerta">
                                      <img id="icono-alerta" src={importantIcon}/>&nbsp;
                                      {etiquetas.mensajeAviso}
                                    </p>
                                  </Col>
                                </Row>
                                <br/>
                            </div>
                            :
                            <ModalMessage
                                mensaje={etiquetas.mensajeSEyCAENoLaboraINE}
                                tipoMensaje={2}
                            />
                        :
                        <ModalMessage
                            mensaje={etiquetas.mensajeNoEncontroSEyCAE}
                            tipoMensaje={1}
                        />
                }
            </div>
        </div>
    );
}
