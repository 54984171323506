
import { useEffect, useState } from "react";
import { Alert, Button, Col, Input, Row, Image, Form, Select, InputNumber } from "antd"

import fotoINE from "../assets/img/ine_credencial.png";
import funcionDeUnCAE from '../assets/img/funcion_de_un_CAE.png';
import funcionDeUnSE from '../assets/img/funcion_de_un_SE.png';
import credencialIcon from '../assets/img/credencial_icon.svg';
import gpsIcon from '../assets/img/gps_icon.svg';
import mapaIcon from '../assets/img/mapa_icon.svg';
import grupo5215 from '../assets/img/Grupo_5215.svg';
import importantIcon from '../assets/img/importante_icon.svg';
import presidenteIcon from '../assets/img/presidente_icon.svg';
import * as etiquetas from './ApplicationResources';
import { useCatalogoStore, useConsultaSEyCAE, useDataSelectStore } from "../hooks";
import { ModalInfo } from "./ModalInfo";
import { Aspirantes } from "../pages"
import { useNavigate } from "react-router-dom";


export const FormBusquedaApirante = ({ isVerified }) => {

  const { Item } = Form;
  const { Option } = Select
  const [formSeCae] = Form.useForm();
  const { startLoadingProcesos, startLoadingEstadosByProceso, listaProcesos, listaEstados } = useCatalogoStore();
  const { startSelectProceso, startSelectEstado, startSelectSeccion } = useDataSelectStore();
  const { consultarSEyCAE } = useConsultaSEyCAE(isVerified);
  const [bloquearCampo, setBloquearcampo] = useState(false);
  const [tipoAspirante, setTipoAspirante] = useState('');
  const [funcionesAspirante, setFuncionesAspirante] = useState();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    startLoadingProcesos(true)
  }, [])

  const onChangeProceso = (value, option) => {
    console.info("valor del proceso", value, option)
    let descProceso = (option !== undefined && option.descProceso) ?
      option.descProceso : "";
    let idProceso = (option !== undefined && option.idProceso) ?
        option.idProceso : "";
    startLoadingEstadosByProceso(value);
    startSelectProceso({ value, descProceso: descProceso, idProceso: idProceso });
  }

  const onChangeEntidad = (value) => {
    try {
      startSelectEstado(value);
    } catch (error) {
      console.error("onChangeEntidad " + error);
    }
  }

  const onSeccionSearch = (e) => {
    console.info("valor de la seccion", e);
    try {
      if (e !== undefined && e.currentTarget !== undefined && e.currentTarget.value !== undefined) {
        let seccion = e.currentTarget.value;
        if (seccion.length > 0) {
          validarYasignarSeccion(seccion)
        }
      }
    } catch (error) {
      console.error("onSeccionSearch " + error);
      startSelectSeccion(0);
    }
  }

  const validarYasignarSeccion = (seccion) => {
    console.info("valor de la seccion a asignar", seccion);
    let regex = /^[0-9]+$/;
    if (seccion !== undefined && seccion !== null) {
      let valorEntero = parseInt(seccion);
      if (regex.test(valorEntero)) {
        console.info("sección correcta");
        startSelectSeccion(seccion);
      }
    }
  }

  const onFinish = (values) => {
    console.info("onFinish");
    consultarSEyCAE();
    formSeCae.resetFields();
  }

  const handelOpenModal = (tipoFuncion) => {
    console.log(tipoFuncion,"wer");
    switch (tipoFuncion) {
      case 'SE':
        setTipoAspirante(tipoFuncion);
        setFuncionesAspirante(funcionDeUnSE);
      break;
      case 'CAE':
        setTipoAspirante(tipoFuncion);
        setFuncionesAspirante(funcionDeUnCAE);
      break;
      default:
        setTipoAspirante(tipoFuncion);
        setFuncionesAspirante(fotoINE);
    }

    setOpenModal(true);
  }


  return (
    <>
      <div key="busca">
        <Row key="busqueda" className="busqueda">
          <div className="contenido" >
            <Row >
              <Col xs={24} sm={24} md={12} lg={12} >
              </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <Button className="colorEnlace"
                          type="link"
                          onClick={(tipoFuncion) => handelOpenModal(tipoFuncion = 'SE')}>
                    ¿Cuál es la función de un SE?
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <Button className="colorEnlace"
                          type="link"
                          onClick={(tipoFuncion) => handelOpenModal(tipoFuncion = 'CAE')}>
                    ¿Cuál es la función de un CAE?
                  </Button>
                </Col>
            </Row>
            <Row >
              <Col xs={24} sm={24} md={24} lg={24} >
                <div className="container-title">
                  <h2>
                    Consulta a tu Supervisor Electoral y<br/> Capacitador-Asistente Electoral
                  </h2>
                  <p className="labelObligatorio">
                    Captura los datos requeridos. Los datos con (<span>*</span>) son obligatorios.
                  </p>
                </div>
              </Col>
            </Row>
            <Form form={formSeCae}
              layout="vertical" scrollToFirstError
              onFinish={onFinish}>
              <Row >
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Row className="camposFiltros "gutter={16}>
                    <Col xs={24} sm={24} md={6} lg={6} >
                      <img className="iconoCampo" src={presidenteIcon}/>
                      <Item
                        label="Proceso electoral"
                        name="proceso" className="label-form campoForm"
                        rules={[{ required: true,
                                  message: "Selecciona un proceso"}]}>
                        <Select
                          placeholder=""
                          onSelect={onChangeProceso}
                          disabled={bloquearCampo}>
                          {listaProcesos &&
                            listaProcesos.map((item, index) => (
                              <Option key={index}
                                idProceso = {item.idProcesoElectoral}
                                value={item.idDetalleProceso}
                                descProceso={item.descripcionDetalle}
                              >
                                {item.descripcionDetalle}
                              </Option>
                            )
                            )}
                        </Select>
                      </Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} >
                      <img className="iconoCampo" src={gpsIcon}/>
                      <Item label="Estado"
                        name="estado" className="label-form campoForm campoEstado"
                        required={true}
                        rules={[{required: true,
                                 message: "Selecciona una entidad"}]}>
                        <Select
                          placeholder=""
                          onSelect={onChangeEntidad}
                          disabled={bloquearCampo}>
                          {listaEstados &&
                            listaEstados.map((item, index) => (
                              <Option key={index}
                                value={item.idEstado}>
                                {item.nombreEstado}
                              </Option>
                            )
                            )}
                        </Select>
                      </Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} >
                      <img className="iconoCampo" src={mapaIcon}/>
                      <Item label={"Sección electoral"}
                        name="seccion"
                        required={true}
                        className="label-form campoForm"
                        rules={[{ required: true,
                                  message: "Selecciona la sección"}]}>
                        <Input
                          placeholder=""
                          onChange={onSeccionSearch}
                          controls={false}
                          maxLength="4" min={1}
                          disabled={bloquearCampo}
                          id="inputSeccion" />
                      </Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} >
                      <div className="buscaSEyCAEBoton" >
                        <Button type="primary"
                          htmlType="submit"
                          disabled={bloquearCampo}>
                          Consultar
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
            <Row className="buscaSEyCAEFoto">
              <Col xs={24} sm={24} md={24} lg={24}>
                <p>
                  <img className="iconoCampo" src={credencialIcon}/>&nbsp;
                  Ubica tu secci&oacute;n  en tu INE.
                  <Button className="colorEnlace"
                          type="link"
                          onClick={(tipoFuncion) => handelOpenModal(tipoFuncion = 'seccion')}>
                    Ver
                  </Button>
                </p>
              </Col>
            </Row>
            <div className="linea"></div>
            <Row gutter={{xs: 8,sm: 16,md: 24, lg: 32}}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Aspirantes/>
              </Col>
            </Row>
            <Row className="buscaSEyCAEAviso">
              <Col xs={24} sm={24} md={24} lg={24}>
                <p id="texto-alerta">
                  <img id="icono-alerta" src={importantIcon}/>&nbsp;
                  {etiquetas.mensajeAviso}
                </p>
              </Col>
            </Row>
            <br/>
          </div>
        </Row>
      </div>

      <ModalInfo
        tipoAspirante={tipoAspirante}
        funcionesAspirante={funcionesAspirante}
        isOpenModal={openModal}
        openModal={setOpenModal}
      />
    </>



  )
}
